import React from 'react'
import { HiArrowLeft, HiDownload, HiOutlineLockClosed, HiOutlineLockOpen } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components/macro'
import { useDownload, withDownloadModal } from '../../../hooks/useDownload'
import { useModal } from '../../../hooks/useModal'
import { useIsAdmin } from '../../../modules/auth'
import { useReportById, useReportStatus } from '../../../modules/reports/hooks/useReports'
import { useCloseReportMutation, useOpenReportMutation } from '../../../modules/reports/mutations'
import Button from '../../atoms/button/Button'
import { ButtonIcon } from '../../atoms/buttonIcon/ButtonIcon'
import { Nav } from '../../atoms/nav/Nav'
import { NavItem } from '../../atoms/navItem/NavItem'
import Tag from '../../atoms/tag/Tag'
import { CloseReportModal } from '../closeReportModal/CloseReportModal'
import { Modal } from '../modal/Modal'

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  height: 60px;
  width: 100%;
  background: #fff;
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
  flex-shrink: 0;
  z-index: 10;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

const StyledButton = styled(Button)`
  padding: 3px 12px;
  height: auto;
`

const StyledNavItem = styled(NavItem)`
  display: flex;
  align-items: center;

  & button {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: pointer;
  }
`

const StyledNav = styled(Nav)`
  padding: 0 16px;
  box-sizing: border-box;
  align-items: center;
`

const NavActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  height: 100%;
  box-sizing: border-box;
  border-left: ${({ theme }) => `solid 1px ${theme.colors.ntrl_light.main}`};
`

const BackButton = styled(ButtonIcon)`
  padding: 0;
`

export const Header = withDownloadModal(({ showBack, showDownload = true, handleDownload, ...rest }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { reportId } = useParams()
  const { data: report, isLoading: isLoadingReport } = useReportById(reportId, { enabled: !!reportId })
  const { data: status, isLoading: isLoadingStatus } = useReportStatus(reportId)
  const [isDownloading] = useDownload({ reportId })
  const [{ isOpen, params }, { showModal, closeModal }] = useModal()
  const isAdmin = useIsAdmin()

  const closeReportMutation = useCloseReportMutation()
  const openReportMutation = useOpenReportMutation()

  const handleCloseReport = () => {
    showModal()
  }

  const onCloseReport = () => {
    closeModal()
    closeReportMutation.mutate({ reportId })
  }

  const handleOpenReport = () => {
    openReportMutation.mutate({ reportId })
  }

  const { isClosed } = status ?? {}
  return (
    <StyledHeader {...rest}>
      <StyledNav>
        {showBack ? (
          <BackButton icon={<HiArrowLeft size={24} color={theme.colors.black.main} />} onClick={() => navigate(-1)} />
        ) : null}

        {!!reportId ? (
          <>
            <StyledNavItem size="m" variant="ntrl_darkest">
              Expediente: {isLoadingReport ? 'Cargando...' : report?.expediente}
            </StyledNavItem>

            {showDownload ? (
              <StyledNavItem variant="primary" onClick={handleDownload} disabled={isDownloading}>
                <HiDownload size={18} />
                {isDownloading ? 'Descargando...' : 'Descargar'}
              </StyledNavItem>
            ) : null}
          </>
        ) : null}
      </StyledNav>

      <NavActions>
        {!isLoadingStatus ? (
          <>
            <Tag variant={isClosed ? 'negative' : 'positive'}>
              {isClosed ? <HiOutlineLockClosed size={18} /> : <HiOutlineLockOpen size={18} />}
              {isClosed ? 'Informe bloqueado' : 'Informe editable'}
            </Tag>
            {isAdmin ? (
              <StyledButton type="button" bordered onClick={isClosed ? handleOpenReport : handleCloseReport}>
                {isClosed ? 'Editar informe' : 'Bloquear informe'}
              </StyledButton>
            ) : null}
          </>
        ) : null}
      </NavActions>

      {isAdmin ? (
        <Modal isOpen={isOpen} onRequestClose={closeModal}>
          <CloseReportModal {...params} onAccept={onCloseReport} onCancel={closeModal} />
        </Modal>
      ) : null}
    </StyledHeader>
  )
})
