import React from 'react'
import { generatePath, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Link, Text } from '../../../../components'
import { useRelatedReports } from '../../../../modules/reports/hooks/useReports'
import { routes } from '../../../../services/Routing'
import { Headline } from '../components/headline'

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 18px;
`

const Content = styled.div`
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
`

const RelatedReportsLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary.main};
  text-decoration: underline;
`

export const ForbiddenReportScreen = React.memo(() => {
  const { reportId } = useParams()
  const { data: relatedReports } = useRelatedReports(reportId)

  return (
    <Container>
      <Content>
        <Headline size="l" weight="500" variant="black">
          ¡Un momento!
        </Headline>
        <Text variant="black" align="center">
          Parece que hay expedientes posteriores al que estás tratando de acceder. Por motivos de seguridad, no se
          permite el trabajo sobre expedientes anteriores. Por favor, acceder a un expediente relacionado más reciente
        </Text>

        <RelatedReportsLinks>
          {relatedReports?.map((report) => (
            <StyledLink key={report.id} to={generatePath(routes.informacionGeneral, { reportId: report.id })}>
              {report.numero_siniestro}
            </StyledLink>
          ))}
        </RelatedReportsLinks>
      </Content>
    </Container>
  )
})
