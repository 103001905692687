import url from 'url'
import RequestHandler from '../request'
import { toast } from 'react-toastify'

const AUTOS_CATEGORY = 5

export const getStoredFileUrl = ({ reportId, type = 'photos', fileId }) => {
  const { getAuthToken } = RequestHandler.sharedInstance()
  return `${url.resolve(
    window.MontesPeritos.config.REACT_APP_BASE_HOST,
    `expedientes/${reportId}/anexos/${type}/${fileId}`,
  )}?access_token=${getAuthToken()}`
}

export const sanitizeFloatValue = (value, defaultValue = 0) => {
  if (!value || isNaN(parseFloat(value))) {
    return defaultValue
  }
  return parseFloat(value)
}

export const showBlockedReportdWarning = function () {
  toast.error('Este informe está bloqueado y no se actualizarán los cambios que efectues.')
}

export const getUsableRelatedReports = function ({ relatedReports }) {
  if (!relatedReports || relatedReports.length === 0) {
    return []
  }

  return relatedReports.filter((relatedReport) => relatedReport.categoria_id !== AUTOS_CATEGORY)
}

export const hasNewerReports = function ({ report, relatedReports }) {
  if (!relatedReports || relatedReports.length === 0) {
    return false
  }

  // We don't want to take into account Autos reports nor -pte reports
  const sanitizedRelatedReports = relatedReports
    .map((relatedReport) => {
      if (relatedReport.categoria_id === AUTOS_CATEGORY) {
        return null
      }
      const numSiniestro = relatedReport?.numero_siniestro ?? ''
      return numSiniestro.toLowerCase().replaceAll('-pte', '')
    })
    .filter(Boolean)

  console.info(sanitizedRelatedReports)

  const reportNum = (report?.numero_siniestro ?? '').toLowerCase().replaceAll('-pte', '').split('-')[1]
  // In this case -autos is a special case where we don't want to block the user
  // from filling this report
  const isAutosReport = report?.categoria_id === AUTOS_CATEGORY
  if (isAutosReport && sanitizedRelatedReports.length > 0) {
    return false
  }

  const currentReportNum = parseInt(reportNum)
  if (isNaN(currentReportNum) && sanitizedRelatedReports.length > 0) {
    return true
  }

  return sanitizedRelatedReports.some((numSiniestro) => {
    const relatedReportNum = parseInt(numSiniestro.split('-')[1])
    if (isNaN(relatedReportNum)) {
      return false
    }
    return relatedReportNum > currentReportNum
  })
}
